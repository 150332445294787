<template>
  <Toast />
  <vue3-snackbar top center success="#BD976E" :duration="2000"></vue3-snackbar>
  <Spinner :loading="orderdetails.loading" />
  <div v-if="!orderdetails.loading" class="order-details-page">
    <div class="order-details">
      <div class="flex items-center justify-between">
        <div
          class="flex items-center space-x-1 text-sm font-medium leading-none text-theme-body"
        >
          <router-link to="/account/orders">All Orders</router-link>
          <span>/ Order Details</span>
        </div>
      </div>

      <div class="w-full bg-white">
        <div
          class="flex items-center justify-between px-6 py-4 border-b border-gray-200 border-opacity-70"
        >
          <div class="flex items-center space-x-3 flex-shrink-0">
            <div
              class="flex items-center justify-center w-8 h-8 flex-shrink-0 rounded-full bg-gray-100"
            >
              <Icon name="document-blue" class="w-6 h-6" />
            </div>
            <div class="text-md font-light">
              <p class="text-brand-primary font-semibold">
                Order #{{
                  orderdetails.data.data
                    ? orderdetails.data.data.order_number
                    : null
                }}
                <span
                  v-if="orderdetails.data?.data?.reference_number"
                  class="ml-10 sm:ml-0 px-2 text-xxs sm:text-xs font-medium text-theme-secondary uppercase leading-none bg-theme-purple-dark bg-opacity-20 sm:px-6 py-1 rounded-full"
                >
                  {{ orderdetails.data?.data?.reference_number || "" }}
                </span>
              </p>

              <!-- <span class="text-theme-body text-xs">Placed on 12 Jan 2022 12:12 PM</span> -->
              <span class="text-theme-body text-xs"
                >Placed on
                {{
                  orderdetails.data.data
                    ? moment(orderdetails.data.data.created_at).format(
                        "DD MMM YYYY hh:mm A"
                      )
                    : null
                }}</span
              >
            </div>
          </div>
          <div class="flex items-center flex-shrink-0">
            <div class="pl-4 flex items-center space-x-3">
              <span
                class="hidden md:inline-block uppercase text-sm tracking-wide text-theme-body"
                >Status</span
              >
              <span class="track-badge green uppercase">{{
                orderdetails.data.data ? orderdetails.data.data.status : null
              }}</span>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col lg:flex-row items-start px-6 py-8 billing-info gap-5"
        >
          <div class="w-full lg:w-3/5">
            <dl class="grid grid-cols-2 items-start gap-4">
              <div>
                <dt>Ship to</dt>
                <dd class="mt-2.5">
                  <!-- <p class="address-title">Main Store</p> -->
                  <p class="address-title">
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.shipping_address.nickname
                          ? orderdetails.data.data.shipping_address.nickname
                          : orderdetails.data.data.shipping_address.company_name
                        : null
                    }}
                  </p>
                  <!-- <span class="address-info">2972 Westheimer Rd. Santa Ana, Illinois 85486</span> -->
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.shipping_address.address1
                      : null
                  }}</span>
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.shipping_address.address2
                      : null
                  }}</span>
                  <span class="address-info"
                    >{{
                      orderdetails.data.data
                        ? orderdetails.data.data.shipping_address.city
                        : null
                    }},{{
                      orderdetails.data.data
                        ? orderdetails.data.data.shipping_address.state
                        : null
                    }}
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.shipping_address.zip_code
                        : null
                    }}</span
                  >
                </dd>
              </div>
              <div>
                <dt class="summary-label">Bill to</dt>
                <dd class="mt-2.5">
                  <!-- <p class="address-title">Main Store</p> -->
                  <p class="address-title">
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.billing_address.company_name
                        : null
                    }}
                  </p>
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.billing_address.address1
                      : null
                  }}</span>
                  <span class="address-info">{{
                    orderdetails.data.data
                      ? orderdetails.data.data.billing_address.address2
                      : null
                  }}</span>
                  <span class="address-info"
                    >{{
                      orderdetails.data.data
                        ? orderdetails.data.data.billing_address.city
                        : null
                    }},
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.billing_address.state
                        : null
                    }}
                    {{
                      orderdetails.data.data
                        ? orderdetails.data.data.billing_address.zip_code
                        : null
                    }}</span
                  >
                </dd>
              </div>
            </dl>
          </div>
          <div class="flex flex-col items-start flex-grow space-y-8">
            <dl class="grid grid-cols-2 items-start gap-4 w-full">
              <div>
                <dt>TOTAL UNITS</dt>
                <dd class="mt-2.5">
                  <p class="text-2xl text-brand-primary">
                    {{
                      orderdetails.data.data
                        ? commaFormat(
                            orderdetails.data.data.OrderLineItemsQuantity
                          )
                        : null
                    }}
                  </p>
                </dd>
              </div>
              <div>
                <dt class="summary-label">SHIPMENTS</dt>
                <dd class="mt-2.5">
                  <p class="text-2xl text-brand-primary">
                    {{
                      orderdetails.data.ships
                        ? Object.keys(orderdetails.data.ships).length
                        : null
                    }}
                  </p>
                </dd>
              </div>
            </dl>
            <div
              class="flex items-center space-x-6"
              v-if="
                orderdetails.data.data
                  ? orderdetails.data.data.payments.length
                  : null
              "
            >
              <div
                class="flex items-center space-x-1.5"
                v-for="(cardnumber, i) in orderdetails.data.data
                  ? orderdetails.data.data.payments
                  : null"
                :key="cardnumber.id"
              >
                <span class="text-theme-secondary uppercase text-md">
                  {{
                    cardnumber.process_data
                      ? cardnumber.process_data.billing
                        ? i == 0
                          ? "Paid Via"
                          : "And"
                        : `paid via card ${++i}`
                      : null
                  }}
                </span>
                <div
                  class="block flex-shrink-0 w-8"
                  v-if="
                    cardnumber.process_data
                      ? cardnumber.process_data.billing
                        ? true
                        : false
                      : false
                  "
                >
                  <img
                    :src="
                      cardnumber.process_data
                        ? cardnumber.process_data.billing
                          ? cardnumber.process_data.billing[
                              'cc-number'
                            ].substring(0, 1) == 4
                            ? '/images/card-providers/visa.png'
                            : cardnumber.process_data.billing[
                                'cc-number'
                              ].substring(0, 1) == 5
                            ? '/images/card-providers/master-card.png'
                            : cardnumber.process_data.billing[
                                'cc-number'
                              ].substring(0, 1) == 3
                            ? '/images/card-providers/american-express.png'
                            : cardnumber.process_data.billing[
                                'cc-number'
                              ].substring(0, 1) == 7
                            ? '/images/card-providers/american-express.png'
                            : ''
                          : null
                        : null
                    "
                    alt="Visa"
                    class="max-w-full"
                  />
                </div>
                <p
                  v-if="
                    cardnumber.process_data
                      ? cardnumber.process_data.billing
                        ? true
                        : false
                      : false
                  "
                  class="text-sm text-theme-body"
                >
                  {{
                    cardnumber.process_data
                      ? cardnumber.process_data.billing
                        ? "** " +
                          cardnumber.process_data.billing["cc-number"].slice(-4)
                        : null
                      : null
                  }}
                </p>
                <p v-else class="text-sm text-theme-body">
                  {{ cardnumber.process_data ? "$" + cardnumber.amount : null }}
                </p>
              </div>
            </div>
            <div
              class="flex items-center space-x-6"
              v-if="
                orderdetails.data.data?.payment_term != null &&
                  orderdetails.data.data?.payments?.length == 0
              "
            >
              <div class="flex items-center space-x-1.5">
                <span class="text-theme-secondary uppercase text-md">
                  Payment Method
                </span>
                <div class="block flex-shrink-0 w-8">
                  <img
                    :src="
                      orderdetails.data.data?.payment_term == 'Bank Transfer'
                        ? '/images/card-providers/bank.jpg'
                        : orderdetails.data.data?.payment_term == 'Zelle'
                        ? '/images/card-providers/zelle.png'
                        : '/images/card-providers/medusa.png'
                    "
                    alt="Visa"
                    class="max-w-full"
                  />
                </div>
                <p>{{ orderdetails.data.data?.payment_term }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full items-center justify-between px-6 py-6">
          <div
            class="flex-grow flex flex-col sm:flex-row sm:items-center gap-x-6 gap-y-4"
          >
            <button class="download-btn" @click="reOrder">
              <Icon name="refresh" />
              <span>Reorder</span>
            </button>
            <template
              v-if="
                orderdetails.data.data
                  ? orderdetails.data.data.status != 'void' &&
                    orderdetails.data.data.invoice != '' &&
                    orderdetails.data.data.invoice.length > 1
                  : null
              "
            >
              <Menu as="div" class="relative inline-block text-left">
                <div>
                  <MenuButton class="download-btn"
                    ><Icon name="document-file" class="w-4 h-4 -mt-0.5" />
                    Download Invoice
                    <DotsVerticalIcon
                      class="ml-2 -mr-1 h-3 w-3"
                      aria-hidden="true"
                    />
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="absolute left-0 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div
                      class="px-1 py-1"
                      v-for="(Inv, index) in orderdetails.data.data.invoice"
                      :key="Inv.id"
                    >
                      <router-link target="_blank" :to="'/invoice/' + Inv.id">
                        <MenuItem v-slot="{ active }">
                          <button
                            :class="[
                              active
                                ? 'bg-violet-500 text-black'
                                : 'text-gray-900',
                              'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                            ]"
                          >
                            Invoice {{ index + 1 }}
                          </button>
                        </MenuItem>
                      </router-link>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </template>

            <template
              v-if="
                orderdetails.data.data
                  ? orderdetails.data.data.status != 'void' &&
                    orderdetails.data.data.invoice != '' &&
                    orderdetails.data.data.invoice.length == 1
                  : null
              "
            >
              <router-link
                v-for="Inv in orderdetails.data.data.invoice"
                :key="Inv.id"
                class="download-btn"
                target="_blank"
                :to="'/invoice/' + Inv.id"
              >
                <Icon name="document-file" class="w-4 h-4 -mt-0.5" />
                <span>View Invoice</span></router-link
              >
            </template>
            <router-link
              v-if="orderdetails.data.ships != ''"
              :to="'/packing-list/' + orderdetails.data.data.trade_gecko_id"
              target="_blank"
              class="download-btn"
            >
              <Icon name="package" class="w-4 h-4 -mt-0.5" />
              <span>View Packing List</span>
            </router-link>
            <button
              class="download-btn"
              @click="downloadCSV(orderdetails.data.data.id, orderdetails.data.data.order_number)"
            >
              <DownloadIcon class="w-3.5 h-3.5" v-if="!downloadCSVLoading" />
              <Spinner v-else className="w-3.5 h-3.5" :loading="downloadCSVLoading" />
              <span>Download CSV</span>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="orderdetails.data.data.status != 'void'"
        class="flex items-center space-x-4 py-2"
      >
        <h3 class="text-lg font-semibold text-brand-primary leading-none">
          Shipment Tracking
        </h3>
        <p
          class="text-base text-theme-body leading-none"
          v-if="orderdetails.data.ships == ''"
        >
          Tracking information will appear below when this order ships.
        </p>
      </div>

      <!-- No Tracking Info State -->
      <div
        class="flex items-center justify-center h-screen max-h-96"
        v-if="orderdetails.data.shipments == ''"
      >
        <div
          class="flex items-center justify-center bg-gray-200 bg-opacity-70 rounded-full w-36 h-36"
        >
          <Icon name="package-filled" class="w-24 h-24" />
        </div>
      </div>
      <div
        class="flex flex-col space-y-5 w-full"
        v-for="(ship, i) in orderdetails.data.shipments"
        :key="i"
      >
        <Disclosure as="div" class="shipment-card">
          <DisclosureButton as="div" class="shipment-card-btn">
            <div class="shipment-btn-info">
              <div class="w-16">
                <div
                  class="icon-text"
                  v-if="ship[0].ship_via.includes('Medusa')"
                >
                  <Icon name="person" />
                  <span>Pickup</span>
                </div>
                <div
                  class="icon-text"
                  v-else-if="ship[0].ship_via.includes('LTL')"
                >
                  <Icon
                    name="person"
                    v-if="ship[0].ship_via.includes('Medusa')"
                  />
                  <span>Pickup</span>
                </div>
                <div class="icon-text" v-else>
                  <img
                    :src="
                      ship[0].ship_via.includes('FedEx')
                        ? '/images/fedex.png'
                        : ship[0].ship_via.includes('USPS')
                        ? '/images/usps.png'
                        : ship[0].ship_via.includes('MDX')
                        ? '/images/medusa.png'
                        : null
                    "
                    alt=""
                    class="h-7 w-auto"
                  />
                  <span v-if="ship[0].ship_via.includes('MDX')">MDX</span>
                </div>
              </div>
              <div class="w-32">
                <p class="title text-center">Shipment</p>
              </div>
              <div class="w-48">
                <div
                  class="track-badge blue"
                  v-if="
                    ship[0].ship_via.includes('LTL') ||
                      ship[0].ship_via.includes('Medusa')
                  "
                >
                  Picked up at Medusa
                </div>
                <div class="track-badge green" v-else>Shipped</div>
              </div>
            </div>
            <div class="shipment-btn-extra">
              <p class="date">
                on {{ moment(ship[0].ship_date).format("MM/DD/YY") }}
              </p>
              <Icon name="down-arrow" class="arrow" />
            </div>
          </DisclosureButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <DisclosurePanel as="div" class="shipment-card-panel">
              <div class="w-full flex items-start justify-between">
                <div class="info-items" v-if="ship[0].ship_via.includes('MDX')">
                  <div class="info-box" v-if="ship[0].tracking_number">
                    <span class="title">TRACKING NO</span>
                    <p>{{ ship[0].tracking_number }}</p>
                  </div>
                  <div class="info-box">
                    <span class="title">SHIPPED ON</span>
                    <p>{{ moment(ship[0].ship_date).format("MM/DD/YY") }}</p>
                  </div>
                  <div class="info-box">
                    <span class="title">Expected Delivery</span>
                    <p>{{ moment(ship[0].ship_date).format("MM/DD/YY") }}</p>
                  </div>
                </div>
                <div
                  class="info-items"
                  v-else-if="ship[0].ship_via.includes('Medusa')"
                >
                  <div class="info-box">
                    <span class="title">Picked up by</span>
                    <p>{{ ship[0].name }}</p>
                  </div>
                  <div class="info-box">
                    <span class="title">PICKUP DATE</span>
                    <p>
                      {{ moment(ship[0].ship_date).format("MM/DD/YY HH:mm") }}
                    </p>
                  </div>
                </div>
                <div class="info-items" v-else>
                  <div class="info-box">
                    <span class="title">TRACKING NO</span>
                    <p>{{ ship[0].tracking_number }}</p>
                  </div>
                  <div class="info-box">
                    <span class="title">SHIPPED ON</span>
                    <p>{{ moment(ship[0].ship_date).format("MM/DD/YY") }}</p>
                  </div>
                </div>
                <!-- <button class="view-btn">View Contents</button> -->
              </div>
              <div class="shipment-card-footer">
                <Button
                  v-if="
                    ship[0].ship_via.includes('LTL') && ship[0].tracking_url
                  "
                  icon="right-arrow"
                  title="See on XPO "
                  :inline="true"
                  class="medium"
                />
                <Button
                  v-if="
                    ship[0].document != null &&
                      (ship[0].ship_via.includes('LTL') ||
                        ship[0].ship_via.includes('Medusa'))
                  "
                  icon="document-file"
                  :title="
                    ship[0].ship_via.includes('Medusa')
                      ? 'Proof of pickup'
                      : 'Download BOL'
                  "
                  :inline="true"
                  class="outlined dark medium"
                  role="link"
                  @click="openInNewTab(ship[0].document)"
                />
                <template
                  v-if="
                    ship[0].tracking_url &&
                      (ship[0].ship_via.includes('FedEx') ||
                        ship[0].ship_via.includes('USPS'))
                  "
                  ><Button
                    icon="document-file"
                    :title="
                      'See on ' + ship[0].ship_via.includes('FedEx')
                        ? 'FedEx'
                        : 'USPS'
                    "
                    :inline="true"
                    class="outlined dark medium"
                    @click="trackingLink(ship[0].tracking_url)"
                /></template>
              </div>
            </DisclosurePanel>
          </transition>
        </Disclosure>

        <!-- <Disclosure as="div" class="shipment-card">
          <DisclosureButton as="div" class="shipment-card-btn">
            <div class="shipment-btn-info">
              <div class="w-16">
                <img src="/images/fedex.png" alt="FedEx" class="h-7 w-auto">
              </div>
              <div class="w-32">
                <p class="title">Shipment 2</p>
              </div>
              <div class="w-48">
                <div class="track-badge">Expected Delivery</div>
              </div>
            </div>
            <div class="shipment-btn-extra">
              <p class="date">on 12/12/21</p>
              <Icon name="down-arrow" class="arrow"/>
            </div>
          </DisclosureButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <DisclosurePanel as="div" class="shipment-card-panel">
              <div class="w-full flex items-start justify-between">
                <div class="info-items">
                  <div class="info-box">
                    <span class="title">TRACKING NO</span>
                    <p>1234494949449492</p>
                  </div>
                  <div class="info-box">
                    <span class="title">SHIPPED ON</span>
                    <p>10/5/21</p>
                  </div>
                </div>
                <button class="view-btn">View Contents</button>
              </div>
              <div class="shipment-card-footer">
                <Button title="See on XPO" :inline="true" class="outlined dark medium"/>
                <Button icon="document-file" title="See on FedEx" :inline="true" class="outlined dark medium"/>
                <Button icon="right-arrow" title="Download Packing List" :inline="true"  class="medium"/>
              </div>
            </DisclosurePanel>
          </transition>
        </Disclosure>

        <Disclosure as="div" class="shipment-card">
          <DisclosureButton as="div" class="shipment-card-btn">
            <div class="shipment-btn-info">
              <div class="w-16">
                <div class="icon-text">
                  <Icon name="truck"/>
                  <span>LTL</span>
                </div>
              </div>
              <div class="w-32">
                <p class="title">Shipment 3</p>
              </div>
              <div class="w-48">
                <div class="track-badge blue">Picked up at Medusa</div>
              </div>
            </div>
            <div class="shipment-btn-extra">
              <p class="date">on 12/12/21</p>
              <Icon name="down-arrow" class="arrow"/>
            </div>
          </DisclosureButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <DisclosurePanel as="div" class="shipment-card-panel">
              <div class="w-full flex items-start justify-between">
                <div class="info-items">
                  <div class="info-box">
                    <span class="title">TRACKING NO</span>
                    <p>1234494949449492</p>
                  </div>
                  <div class="info-box">
                    <span class="title">Order status</span>
                    <p>Shipped</p>
                  </div>
                  <div class="info-box">
                    <span class="title">SHIPPED ON</span>
                    <p>10/5/21</p>
                  </div>
                </div>
                <button class="view-btn">View Contents</button>
              </div>
              <div class="shipment-card-footer">
                <Button title="See on XPO" :inline="true" class="outlined dark medium"/>
                <Button icon="document-file" title="See on FedEx" :inline="true" class="outlined dark medium"/>
                <Button icon="right-arrow" title="Download Packing List" :inline="true"  class="medium"/>
              </div>
            </DisclosurePanel>
          </transition>
        </Disclosure>

        <Disclosure as="div" class="shipment-card">
          <DisclosureButton as="div" class="shipment-card-btn">
            <div class="shipment-btn-info">
              <div class="w-16">
                <div class="icon-text">
                  <Icon name="person"/>
                  <span>Pickup</span>
                </div>
              </div>
              <div class="w-32">
                <p class="title">Shipment 4</p>
              </div>
              <div class="w-48">
                <div class="track-badge blue">Picked up at Medusa</div>
              </div>
            </div>
            <div class="shipment-btn-extra">
              <p class="date">on 12/12/21</p>
              <Icon name="down-arrow" class="arrow"/>
            </div>
          </DisclosureButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <DisclosurePanel as="div" class="shipment-card-panel">
              <div class="w-full flex items-start justify-between">
                <div class="info-items">
                  <div class="info-box">
                    <span class="title">Picked up by</span>
                    <p>Yash Bhardwaj</p>
                  </div>
                  <div class="info-box">
                    <span class="title">PICKUP DATE</span>
                    <p>10/5/21  10:15 AM</p>
                  </div>
                </div>
                <button class="view-btn">View Contents</button>
              </div>
              <div class="shipment-card-footer">
                <Button title="See on XPO" :inline="true" class="outlined dark medium"/>
                <Button icon="document-file" title="See on FedEx" :inline="true" class="outlined dark medium"/>
                <Button icon="right-arrow" title="Download Packing List" :inline="true"  class="medium"/>
              </div>
            </DisclosurePanel>
          </transition>
        </Disclosure> -->
      </div>
    </div>
    <div class="order-summary">
      <OrderSummary class="on-summary has-padding" :summary="ordersummary" />

      <div class="w-full bg-white">
        <OrderItems
          :items="
            sortItems(
              orderdetails.data.data
                ? orderdetails.data.data.order_line_items
                : null
            )
          "
        />
      </div>

      <!-- Removed Products Block -->
      <div
        class="w-full bg-white"
        v-if="orderdetails.data.data.cancel_line_items.length > 0"
      >
        <OrderItems
          :items="
            sortItems(
              orderdetails.data.data
                ? orderdetails.data.data.cancel_line_items
                : null
            )
          "
        >
          <template #header>
            <div class="info-title">
              <div class="title">
                <Icon name="package-filled" />
                <span>Products removed from order</span>
              </div>
              <div
                class="tooltip"
                v-tippy="{
                  content:
                    'Rarely, products that we thought were in stock or in perfect condition to ship are not. We have removed these products from the order and you will not be charged for them or any tax associated with them. ',
                  placement: 'bottom-end',
                }"
              >
                <Icon name="info" />
              </div>
            </div>
          </template>
        </OrderItems>
      </div>
    </div>

    <!-- <OrderAgain
      :is-visible="isReorderVisible"
      :items="
        sortItems(
          orderdetails.data.data
            ? orderdetails.data.data.order_line_items
            : null
        )
      "
      :info="{}"
      @close="isReorderVisible = false"
    /> -->

    <!-- Cart -->
    <Cart
      :showCart="isReorderVisible"
      @close="isReorderVisible = false"
      :heading="heading"
      :isEmpty="
        !isDataLoading &&
          availableItems.length == 0 &&
          unavailableItems.length == 0
      "
      emptyDesc="You can go to order details to reorder again."
    >
      <!-- Cart Content Start -->
      <div v-if="isDataLoading" class="h-full pt-8">
        <Spinner :loading="isDataLoading" />
      </div>
      <template v-else>
        <p class="text-sm font-light text-theme-body mb-3">
          You can reorder all products or adjust quantities
        </p>

        <div class="select-none" v-if="availableItems.length">
          <div class="cart-expanded -mt-5">
            <!-- Group Item -->
            <div
              class="cart-group-item"
              v-for="item in availableItems.sort((a, b) =>
                a.product_name.localeCompare(b.product_name)
              )"
              :key="item.id"
            >
              <div class="cg-info">
                <img :src="item.image_url" />
                <div class="flex flex-col flex-grow sm:flex-grow-0 space-y-1.5">
                  <p>{{ item.product_name }}</p>
                  <span class="variant">{{ item.name }}</span>
                  <span class="price">${{ currencyFormat(item.price) }}</span>
                </div>
                <p class="sm:hidden">
                  ${{ currencyFormat(item.price * item.qty) }}
                </p>
              </div>
              <div class="cg-amount">
                <PlusMinusInput
                  :id="item.id"
                  v-model:modelValue="item.qty"
                  :max="item.available_stock"
                  :min="1"
                />
                <p class="hidden sm:inline-block">
                  ${{ currencyFormat(item.price * item.qty) }}
                </p>
                <button
                  class="opacity-100 md:opacity-100 group-hover:opacity-100 transition cursor-pointer bg-transparent focus:outline-none"
                  @click="removeFromItems(item)"
                >
                  <Icon name="close" class="w-5 h-5 text-theme-red" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Disclosure
          v-slot="{ open }"
          as="div"
          class="select-none"
          :defaultOpen="true"
          v-if="unavailableItems.length > 0"
        >
          <!-- Group List -->
          <DisclosureButton as="div" class="cart-expander">
            <h3>
              Unavailable Products:
              {{ unavailableItems.length }}
            </h3>
            <div class="flex-grow flex items-center truncate">
              <svg
                width="600"
                height="1"
                viewBox="0 0 600 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="4.37114e-08"
                  y1="0.5"
                  x2="600"
                  y2="0.500026"
                  stroke="#EAEAEA"
                  stroke-dasharray="11 11"
                />
              </svg>
            </div>
            <ChevronRightIcon
              class="flex-shrink-0 w-5 h-5 text-brand-body"
              :class="open ? 'transform rotate-90' : ''"
            />
          </DisclosureButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-y-95"
            enter-to-class="transform opacity-100 scale-y-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-y-100"
            leave-to-class="transform opacity-0 scale-y-95"
          >
            <DisclosurePanel class="cart-expanded -mt-5">
              <!-- Group Item -->
              <div
                class="cart-group-item opacity-60"
                v-for="item in unavailableItems.sort((a, b) =>
                  a.unavailable - b.unavailable
                )"
                :key="item.id"
              >
                <div class="cg-info ">
                  <img :src="item.image_url" />
                  <div
                    class="flex flex-col flex-grow sm:flex-grow-0 space-y-1.5"
                  >
                    <p>{{ item.product_name }}</p>
                    <span class="variant">{{ item.name }}</span>
                  </div>
                </div>
                <div class="cg-amount">
                  <span class="text-xs font-normal text-brand-body pr-2"
                    >{{ item.unavailable ? 'Not Available' : 'Out of Stock' }}</span
                  >
                </div>
              </div>
            </DisclosurePanel>
          </transition>
        </Disclosure>
      </template>
      <!-- Cart Footer -->
      <template #footer>
        <div
          class="price-shipping-info mb-3"
          v-if="unavailableItems.length > 0"
        >
          <button class="psi__close-btn">
            <Icon name="close" />
          </button>

          <p class="psi__text flex items-center space-x-0.5 mb-1.5">
            <Icon
              name="info-filled"
              class="w-3.5 h-3.5 mr-1.5 -mt-0.5 text-brand"
            />
            <span class="psi__text--bold">Some products are not available</span>
          </p>

          <p class="psi__text psi__text--xs">
            There are some products in your order that are no longer available
            and have to be removed before you can checkout.
          </p>
          <!-- Notification Alert Checkbox -->
          <div
            class="flex items-center justify-start whitespace-nowrap mt-2 text-ellipsis overflow-hidden w-full select-none"
            v-if="unavailableItems.filter(i => i.unavailable == false).length > 0"
          >
            <span>
              <input
                id="notify-inv"
                name="notify-inv"
                value="notify"
                v-model="notifyCheck"
                type="checkbox"
                class="h-3.5 w-3.5 border-2 mb-0.5
                border-brand-gray border-opacity-50 rounded text-brand-secondary bg-transparent
                focus:ring-transparent"
              />
              <label
                for="notify-inv"
                class="text-xs font-normal text-theme-secondary whitespace-nowrap"
              >
                Alert me when products are available.
              </label>
            </span>
          </div>
        </div>

        <div
          class="mt-6 grid gap-8"
          v-if="!isDataLoading"
          :class="{
            'grid-cols-2 ': availableItems.length || unavailableItems.length,
          }"
        >
          <Button
            title="Cancel"
            :outline="true"
            class="medium-large dark"
            :disabled="isProcessing"
            @click="isReorderVisible = false"
          />
          <Button
            v-if="availableItems.length || unavailableItems.length"
            :disabled="
              (availableItems.length || unavailableItems.filter(e => e.unavailable == false).length)
              ? false 
              : true
            "
            :title="
              availableItems.length < 1 
                ? 'Notify Me' 
                  : unavailableItems.length == 0
                  ? `Add all to Cart` 
                : `Add to Cart`
            "
            class="medium-large"
            @mouseup="addToCart"
            :loader="isProcessing"
          />
        </div>
      </template>
      <!-- Cart Content End -->
    </Cart>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";
import { ref, inject, computed, watch } from "vue";
import Toast from "@/components/Toast.vue";
import OrderSummary from "./modules/OrderSummary.vue";
import OrderItems from "./modules/OrderItems.vue";
import { DotsVerticalIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
} from "@headlessui/vue";
import Account from "@/modules/Account";
import Cart from "@/components/Cart.vue";
import { DownloadIcon } from "@heroicons/vue/outline";
import { useStore } from "vuex";

export default {
  name: "OrderDetails",
  mixins: [Mymixin],
  components: {
    Toast,
    OrderSummary,
    OrderItems,
    DotsVerticalIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Cart,
    ChevronRightIcon,
    DownloadIcon,
  },
  methods: {
    sortItems(data) {
      return data
        ? data.sort((a, b) => a.productName.localeCompare(b.productName))
        : [];
    },

    getTotal(data) {
      let temp = 0;
      if (data)
        data.forEach((element) => (temp += element.qty * element.singlePrice));
      return temp;
    },

    trackingLink(data) {
      window.open(data);
    },
    moment: function(date) {
      return moment(date);
    },
    openInNewTab(url) {
      window.open(url, "_blank", "noreferrer");
    },
    downloadCSV(id, order_number){
      this.downloadCSVLoading = true;
      let time = moment(new Date()).valueOf();
      let file_name = order_number + "_" + time + ".csv";

      this.axios.authApi
        .get("/order/csv/" + id, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file_name);
          document.body.appendChild(link);
          link.click();
          this.downloadCSVLoading = false;
        })
        .catch((error) => {
          this.downloadCSVLoading = false;
          console.log(error)
        })
        .finally(() => {
          this.downloadCSVLoading = false;
        });
    }
  },
  computed: {
    ordersummary() {
      const orders = {
        data: {
          // subTotal : (this.getTotal(this.orderdetails.data.data.order_line_items)).toFixed(2),
          subTotal: this.getTotal(
            this.orderdetails.data.data.order_line_items
          ).toFixed(2),
          taxTotal: this.orderdetails.data.data.taxes,
          shipping: this.orderdetails.data.data.shippingTotal,
          gTotal: Number(this.orderdetails.data.data.orderTotal).toFixed(
            2
          ),
          discount: this.orderdetails.data.data.discounts,
        },
      };
      return orders;
    },
    shippingId: {
      get() {
        return this.checkoutData.data.shipping_id;
      },
      set(newValue) {
        this.checkoutData.data.shipping_id = newValue;
      },
    },
    billingId: {
      get() {
        return this.checkoutData.data.billing_id;
      },
      set(newValue) {
        this.checkoutData.data.billing_id = newValue;
      },
    },
    shipTo: {
      get() {
        return this.addresses.data.find(
          (address) =>
            address.trade_gecko_id == this.checkoutData.data.shipping_id
        );
      },
    },
    billTo: {
      get() {
        return this.addresses.data.find(
          (address) =>
            address.trade_gecko_id == this.checkoutData.data.billing_id
        );
      },
    },
  },
  data() {
    return {};
  },
  setup() {
    const axios = inject("axios");
    const store = useStore();
    const shipping_address = ref(null);
    const notifyCheck = ref(true);

    const isReorderVisible = ref(false);
    const allAvailableProducts = ref([]);
    const allUnavailableProducts = ref([]);
    const isLoading = ref(true);
    const isProcessing = ref(false);
    const downloadCSVLoading = ref(false);

    const heading = computed(() =>
      orderdetails.data.data
        ? "Reorder products from #" + orderdetails.data.data.order_number
        : null
    );

    const availableItems = computed(() => allAvailableProducts.value);

    const unavailableItems = computed(() => allUnavailableProducts.value);

    const isDataLoading = computed(() => isLoading.value);

    //Get Order Details
    const {
      orderdetails,
      getOrderdetails,
      reOrderdetails,
      getReOrderDetails,
    } = Account();
    getOrderdetails();

    watch(reOrderdetails, (details) => {
      isLoading.value = details.loading;
      if (details.data.data) {
        const { availble_products, unavailble_products } = details.data.data;
        allAvailableProducts.value = availble_products || [];
        allUnavailableProducts.value = unavailble_products || [];
      }
    });

    function reOrder() {
      getReOrderDetails();
      isReorderVisible.value = true;
    }

    const removeFromItems = (prod) => {
      const pidx = allAvailableProducts.value.findIndex(
        (p) => p.sku === prod.sku
      );
      allAvailableProducts.value.splice(pidx, 1);
    };

    function addToCart() {
      const items = availableItems.value.map((i) => ({
        variant_id: i.id,
        qty: i.qty,
      }));
      let notify = [];

      if (notifyCheck.value) {
        notify = unavailableItems.value.filter(i => i.unavailable == false).map(j => j.id)
      }

      if(items.length > 0 || notify > 0){
        isProcessing.value = true;
        axios.authApi
          .post("/add-products-to-cart", { items, notify })
          .then((resp) => {
            if (resp.status) {
              isReorderVisible.value = false;

              // Pull Cart Items
              store.dispatch("getCart", { ax: axios });
            } else {
              console.log(resp.error);
            }
          })
          .catch(function(error) {
            console.log(error);
          })
          .finally(() => (isProcessing.value = false));
      }
    }

    return {
      orderdetails,
      shipping_address,
      isReorderVisible,
      notifyCheck,
      availableItems,
      unavailableItems,
      reOrderdetails,
      getReOrderDetails,
      reOrder,
      addToCart,
      heading,
      axios,
      removeFromItems,
      isDataLoading,
      isProcessing,
      downloadCSVLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.order-details-page {
  @apply flex flex-col xl:flex-row items-start gap-6 w-full pb-10;
  @include maxContainer();

  .download-btn {
    @apply w-auto inline-flex items-center text-sm text-white bg-theme-secondary hover:bg-theme-secondary hover:bg-opacity-95 space-x-2 tracking-wide px-3 py-2.5 rounded leading-none;
    min-width: 100px;
  }

  .track-badge {
    @apply inline-block text-center text-sm font-medium leading-none px-5 py-2 rounded-lg;
    background: rgba(214, 193, 173, 0.24);
    color: #99674a;

    &.green {
      background: rgba(173, 214, 177, 0.24);
      color: #4a995b;
    }

    &.blue {
      background: rgba(173, 192, 214, 0.24);
      color: #4a6f99;
    }
  }

  .order-details {
    @apply w-full xl:w-auto flex-grow space-y-6;
  }

  .order-summary {
    @apply flex flex-col w-full max-w-sm flex-shrink-0 space-y-8 mt-10;
  }
}

.checkblock {
  @apply relative flex flex-col rounded-lg border border-theme-border bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 focus:outline-none select-none;
  .checkmark-label {
    @apply flex w-full justify-end -mb-2;

    .checkmark {
      @apply border rounded-full w-5 h-5 flex flex-shrink-0 justify-center items-center;

      &.active {
        @apply bg-theme-indigo border-theme-indigo text-white;
      }

      &.inactive {
        @apply bg-white border-theme-border;
      }
    }
  }
  .item-name {
    @apply text-base font-semibold text-theme-secondary mb-1;
  }

  .item-desc {
    @apply block text-sm text-theme-secondary font-normal text-opacity-75 w-11/12 mb-3;
  }
}

.billing-info {
  dl {
    @apply grid grid-cols-2 gap-6 text-sm text-theme-body-light;

    dt {
      @apply block uppercase text-xs tracking-wider font-light;
    }
  }

  .address-title {
    @apply block text-base text-theme-secondary font-medium mb-0.5;
  }

  .address-info {
    @apply block text-md text-theme-body font-light;
    max-width: 200px;
  }
}

.info-title {
  @apply flex items-center justify-between px-4 py-3;
  .title {
    @apply flex items-center text-md text-theme-secondary font-light space-x-2;
    svg {
      @apply w-5 h-5;
    }
  }
  .price {
    @apply text-sm text-black font-semibold;
  }
}

.shipment-card {
  @apply w-full bg-white rounded text-theme-dark leading-5;

  .shipment-card-btn {
    @apply flex items-center justify-between w-full px-6 py-4 cursor-pointer;

    .icon-text {
      @apply flex items-center space-x-1;
      color: #4f6278;

      svg {
        @apply h-5 w-5 flex-shrink-0;
      }

      span {
        @apply text-md leading-none;
      }
    }

    .shipment-btn-info {
      @apply flex items-center flex-grow space-x-5;

      .title {
        @apply text-base text-theme-body-light;
      }
    }

    .shipment-btn-extra {
      @apply w-2/5 flex items-center justify-between;

      .date {
        @apply text-brand-primary text-base font-medium text-md;
      }

      .arrow {
        @apply w-5 h-5 flex-shrink-0;
      }
    }
  }

  .shipment-card-panel {
    @apply flex flex-col w-full px-6 py-6 border-t border-gray-200 border-opacity-70 space-y-5;

    .view-btn {
      @apply w-36 text-sm text-right font-medium text-blue-300 uppercase flex-shrink-0 mt-1;
    }

    .info-items {
      @apply flex items-stretch flex-grow space-x-5 divide-x divide-theme-border divide-opacity-50 -mx-8;

      .info-box {
        @apply px-8 h-full text-left;

        .title {
          @apply text-xxs font-light text-theme-body uppercase;
        }

        p {
          @apply text-sm font-semibold text-black;
        }
      }
    }

    .shipment-card-footer {
      @apply flex items-center justify-end space-x-3 w-full py-3;
    }
  }
}
.info-title {
  @apply flex items-center justify-between px-4 py-3;
  .title {
    @apply flex items-center text-md text-theme-secondary font-light space-x-2;
    svg {
      @apply w-5 h-5;
    }
  }
  .tooltip {
    svg {
      @apply w-5 h-5;
    }
    @apply flex items-center text-sm text-theme-light-shade font-semibold;
  }
}
.cart-empty {
  @apply flex flex-col items-center space-y-2 px-4;
  p {
    @apply text-base text-brand-secondary font-semibold text-center;
  }
  span {
    @apply text-sm text-brand-body w-72 text-center;
    a {
      @apply border-b border-dashed border-brand-body;
    }
  }
}
</style>
